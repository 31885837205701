import { useEffect, useState } from 'react';
// import { useAppSelector } from '@hooks/useAppSelector';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { TypoSBold, TypoXSBold } from '@atoms/Typos';
import routes from '@utils/routes';
import styles from './css';

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;

const pagesToShowCountdown = [
  routes.browse,
  routes.properties,
  routes.property,
  routes.v2FlightReservationsNew,
];

const CampaignCountdown = () => {
  const { lang, t } = useTranslation('common');
  const { pathname } = useRouter();

  // const {
  //   campaign: { campaigns },
  // } = useAppSelector((state) => ({
  //   campaign: state.campaign,
  // }));

  const [campaignActiveInterval, setCampaignActiveInterval] =
    useState<string>();

  // const diff = dayjs(campaigns?.early_bird?.end_at).diff();
  const diff = 0;

  useEffect(() => {
    if (diff) {
      const interval = setInterval(
        () =>
          setCampaignActiveInterval(
            dayjs()
              .set('hour', Math.floor((diff / HOUR) % 24))
              .set('minute', Math.floor((diff / MINUTE) % 60))
              .set('second', Math.floor((diff / SECOND) % 60))
              .format(lang === 'ja' ? ' H 時間 m 分 s 秒' : ' HH:mm:ss')
          ),
        1000
      );

      return () => clearInterval(interval);
    }
  }, [diff, lang]);

  if (
    !(
      diff > 0 &&
      !!campaignActiveInterval &&
      pagesToShowCountdown.includes(pathname)
    )
  ) {
    return null;
  }

  return (
    <div className="campaign-countdown">
      <div className="campaign-countdown-texts-cont">
        <TypoXSBold text={t('campaignCountdown')} />
        <TypoSBold text={campaignActiveInterval} />
      </div>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default CampaignCountdown;
